import React from "react";
import Link from "components/Common/Link";
import Card from "components/Cards/Card";
import Layout from "layouts/EmptyLayout";

export default function Contact() {
  return (
    <>
      <Layout title="Contact">
        <div className="container mx-auto px-4 h-full">
          <div className="w-full lg:w-8/12 p-6 flex mx-auto">
            <Card
              title="Thank you"
              className="text-center p-10 lg:py-24 px-6 bg-camping"
            >
              Thanks for your contact.
            </Card>
          </div>
        </div>
      </Layout>
    </>
  );
}
